.portrait {
  width: calc(47% - 200px);
  box-sizing: border-box;
  background-color: var(--first-color);
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
  position: absolute;
  bottom: -80px;
  left: 10%;
  &_img {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    &_container {
      height: 500px;
      width: 100%;
      display: flex;
      align-items: start;
    }
  }
  &_description {
    margin: 0;
    padding: 50px 65px 50px 65px;
    font-size: 18px;
    font-weight: 400;
  }
}

@media only screen and (min-width: 766px) and (max-width: 1024px) {
  .portrait {
    width: calc(45% - 40px);
    margin-right: 0;
    bottom: auto;
  }
}

@media screen and (max-width: 765px) {
  .portrait {
    position: relative;
    left: 0;
    width: 100%;
    margin-top: -50px;
    margin-right: 0;
  }
}
