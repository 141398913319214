.contact {
  background-color: var(--second-color);
  display: flex;
  flex-direction: row-reverse;
  padding: 100px 140px 100px 140px;

  &_caption {
    width: 50%;
    & h2 {
      color: var(--third-color);
      font-family: 'Abril Fatface';
      font-weight: 400;
      font-size: 83px;
      line-height: 100px;
      margin-bottom: 15px;
    }
    & p {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &_form {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-right: 80px;
    align-items: flex-end;
    & input {
      height: 50px;
    }
    & textarea {
      height: 150px;
      padding-top: 21px;
    }
    &_input {
      background-color: var(--second-color);
      border: 2px solid var(--fifth-color);
      margin-bottom: 24px;
      width: 100%;
      border-radius: 10px;
      color: var(--fifth-color);
      font-weight: 400;
      font-size: 15px;
      font-family: 'montserrat';
      font-weight: 500;
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
      &::placeholder {
        font-family: 'montserrat';
        color: var(--fifth-color);
        opacity: 0.6;
        font-size: 15px;
        font-weight: 500;
      }
    }
    & button {
      background-color: var(--third-color);
      font-size: 20px;
      font-weight: 800;
      text-transform: uppercase;
      height: 35px;
      width: 250px;
      border: none;
      border-radius: 50px;
      transition: 200ms;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      &:hover {
        background-color: var(--fourth-color);
        transition: 200ms;
      }
    }
  }
}

@media only screen and (min-width: 766px) and (max-width: 1024px) {
  .contact {
    padding: 10% 10%;
    &_caption h2 {
      font-size: 45px;
      line-height: 50px;
      margin-top: 30%;
    }
    &_form {
      margin-right: 5%;
    }
  }
}

@media screen and (max-width: 765px) {
  .contact {
    padding: 0 20px;
    flex-direction: column;
    & h2 {
      font-size: 40px;
      line-height: 45px;
      margin-top: 50px;
    }
    &_caption {
      width: 100%;
    }
    &_form {
      width: 100%;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
}
