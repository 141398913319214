.a-propos {
  background-color: var(--fifth-color);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 90px 100px 90px 100px;
  position: relative;
  align-items: center;
  &_content {
    width: 50%;
    color: var(--first-color);
    box-sizing: border-box;
    &_title {
      font-family: 'Abril Fatface';
      font-weight: 500;
      font-size: 90px;
      line-height: 100px;
      color: var(--first-color);
      margin-bottom: 25px;
      &_star {
        height: 1em;
      }
    }
    &_text {
      color: var(--first-color);
      font-size: 24px;
      margin-bottom: 20px;
    }
    &_link {
      background-color: var(--fourth-color);
      width: 75%;
      color: var(--fifth-color);
      display: flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px 0 30px;
      height: 50px;
      border-radius: 25px;
      font-weight: 500;
      font-size: 24px;
      margin-top: 30px;
      & img {
        height: 1em;
      }
    }
  }
}

@media only screen and (min-width: 766px) and (max-width: 1024px) {
  .a-propos {
    display: flex;
    padding: 10% 10% 10% 10%;

    &_content {
      width: 40%;
      box-sizing: border-box;
      &_link {
        height: fit-content;
      }
    }
  }
}

@media screen and (max-width: 765px) {
  .a-propos {
    padding: 100px 35px 0 35px;
    flex-direction: column;

    &_content {
      width: 100%;
      &_title {
        font-size: 45px;
        line-height: 50px;
      }
      &_text {
        font-size: 20px;
      }
      &_link {
        width: 100%;
        box-sizing: border-box;
        font-size: 18px;
        height: fit-content;
      }
    }
  }
}
