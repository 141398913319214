.landing {
  z-index: 1;
  position: relative;
  padding-top: 100px;
  height: 100vh;
  box-sizing: border-box;
  &_portfolio {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    width: 100%;
    box-sizing: border-box;

    & .filled {
      z-index: 99999;
      position: relative;
      width: 100%;
      box-sizing: border-box;
    }
    &_lines {
      width: 100%;
      box-sizing: border-box;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      font-family: 'abril fatface';
      font-size: 13vw;
      line-height: 13vw;
      font-weight: 400;
      color: var(--third-color);
    }
    & .outline {
      color: var(--first-color);
      position: relative;
      z-index: 3;
    }
    &_outline {
      color: var(--first-color);
      text-shadow:
        1px 1px 0 var(--third-color),
        -1px 1px 0 var(--third-color),
        -1px -1px 0 var(--third-color),
        1px -1px 0 var(--third-color);
    }
  }
  &_img {
    z-index: 4;
    position: absolute;
    bottom: 50px;
    right: 160px;
    height: 70%;
  }
  &_links {
    height: 250px;
    width: 250px;
    box-sizing: border-box;
    background-color: var(--first-color);
    padding: 75px 45px 75px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    bottom: 135px;
    left: 160px;
  }
}

.scroll {
  width: 100px;
  height: 100px;
  border-radius: 65px;
  background-color: var(--third-color);
  color: var(--fourth-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

@media only screen and (min-width: 766px) and (max-width: 1023px) {
  .landing {
    padding-left: 10%;
    padding-right: 10%;
    &_portfolio {
      position: absolute;
      top: 50%;
      left: 50%;
      &_lines {
        text-align: center;
      }
      & .phone {
        display: block;
      }
    }
    &_img {
      top: 30%;
      right: 0;
    }
    &_links {
      width: fit-content;
      top: 63%;
      left: 0;
      padding-left: 10%;
      box-sizing: border-box;
    }
  }
}

@media only screen and (max-width: 765px) {
  .landing {
    padding: 150px 0 0 0;
    &_portfolio {
      transform: translate(-50%, -60%);
      &_lines {
        text-align: center;
      }
    }
    & .phone {
      display: block;
    }
    &_img {
      width: -calc(width - 50px);
      right: 0;
      bottom: -50px;
      height: 375px;
    }
    &_links {
      font-size: 14px;
      bottom: 50px;
      left: 20px;
      width: 55%;
    }
  }
}
